/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useContext } from "react"
// import React, { useEffect, useContext } from "react"
import PropTypes from "prop-types"
// import { useStaticQuery, graphql } from "gatsby"
// import { makeStyles } from "@material-ui/core/styles"

// import Header from "./header"
import GridContainer from "components/Grid/GridContainer.js"
import Header from "components/Header/Header.js"
import HeaderLinks from "components/Header/HeaderLinks.js"
import Footer from "components/Footer/Footer.js"
import "typeface-roboto"
import "./layout.css"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "context/GlobalContextProvider"

const dashboardRoutes = []

const Layout = ({ children }) => {
  const state = useContext(GlobalStateContext)
  return (
    <>
      {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
      <Header
        color="white"
        routes={dashboardRoutes}
        brand="菲哥極光報名表"
        rightLinks={<HeaderLinks />}
        absolute
      />
      {/* <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
        }}
      > */}
      {/* <div className={classes.container}> */}
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0px 1.0875rem 1.45rem`,
          paddingTop: 40,
        }}
      >
        <GridContainer justify="center">
          <main>{children}</main>
        </GridContainer>
        <Footer blackFont={true} />
        {/* </div> */}
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
